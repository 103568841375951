<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm de segurança para Janelas</h2>

            <p>
              O insulfilm de segurança para janelas torna o seu vidro mais
              resistente a danos e mais forte, evita danos e acidentes
              familiares e protege os seus bens e entes queridos.
            </p>

            <p>
              A sua aplicação é muito adequada para projetos residenciais,
              escritórios e casas de banho. Em caso de invasão, o filme
              dificulta a entrada na instituição, evitando tentativas de roubo e
              vandalismo.
            </p>

            <p>
              Quando usado no banheiro, sua família estará protegida caso o
              vidro se quebre, pois todos os fragmentos serão fixados no filme
              para evitar acidentes.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Benefícios do insulfilm de segurança para janelas</h3>

            <ul class="list-disc">
              <li>Anti-vandalismo</li>
              <li>
                Camada anti-risco com maior resistência à abrasão do mercado
              </li>
              <li>A película não rasga, e não pode ser perfurada</li>
              <li>
                Camada protetora para vidros refletivos (Habitat) monolíticos e
                temperados
              </li>
              <li>
                Estabilidade de cor (anti-amarelamento) e proteção UV de no
                mínimo 95%
              </li>
              <li>Processo de fabricação da película, feita 100% nos EUA</li>
            </ul>

            <h4>Modelo PS4</h4>

            <p>
              É recomendado até 120 Kg, exemplo: vidros de janelas, portas de
              vidros e box de banheiros.
            </p>

            <h4>Modelo PS7</h4>

            <p>
              É recomendado a partir de 120 Kg, exemplo: fachadas de vidros,
              <router-link :to="{ name: 'insulfilmServicosVitrines' }"
                >vitrines</router-link> e peças de vidros com 1800mm x 3000mm x 10mm ou acima.
            </p>

            
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

  const Photo = () =>
  import(
    /* webpackChunkName: "Photo" */ "@/components/photo/photo.vue"
  );

export default {

  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,    
  },

  data() {
    return {
      index: null,
      photos: [
        //segurança
        {
          description:
            "Película de segurança usado para evitar que o vidro se quebre",
          idLocation: 1,
          src: "/images/fotos/seguranca/pelicula-seguranca-vidros",
          thumb: "/images/fotos/seguranca/pelicula-seguranca-vidros",
          title: "Película para Janela",
        },
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm de segurança para Janelas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm de segurança para janela em comércios, sacadas, escritórios, guaritas e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>